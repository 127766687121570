'use client'

import { formatUnits } from 'viem'

import { useLockdropStats } from '@/hooks/useLockdropStats'
import { RatioProgressBar } from '@/components/ui/RatioProgressBar'

export const StakingProgressSection = () => {
  const { totalLockedAmount, loading } = useLockdropStats()

  const numerator = Number(formatUnits(BigInt(totalLockedAmount), 18))
  const denominator = 200_000_000

  const percent = (numerator / denominator) * 100

  const text =
    loading || BigInt(totalLockedAmount) === 0n
      ? 'Calculating SYN staked…'
      : `${percent.toFixed(1)}% SYN staked`

  return (
    <div className="mt-1 space-y-1">
      <div className="text-white/65 text-xs">{text}</div>
      <RatioProgressBar
        numerator={loading || BigInt(totalLockedAmount) === 0n ? 0 : numerator}
        denominator={denominator}
        needTooltip={false}
      />
    </div>
  )
}
