import Link from 'next/link'

import { AccountsContent } from '@/components/Teaser/AccountsContent'
import { ArchitectureContent } from '@/components/Teaser/ArchitectureContent'
import { AIContent } from '@/components/Teaser/AIContent'
import { PointsContent } from '@/components/Teaser/PointsContent'
import { LiquidityNodesContent } from '@/components/Teaser/LiquidityNodesContent'
import { RestakedValidatorsContent } from '@/components/Teaser/RestakedValidatorsContent'
import { StakingContent } from '@/components/Teaser/StakingContent'
interface WindowMetadata {
  key: string
  aliases: {
    [key: string]: string
  }
  title: string
  content: React.ReactNode
  showInTerminal: boolean
}

export const windowMetadata: WindowMetadata[] = [
  {
    key: '01',
    aliases: {
      '1': '01',
      ac: '01-Ac',
    },
    title: '01-Accounts',
    content: <AccountsContent />,
    showInTerminal: true,
  },
  {
    key: '02',
    aliases: {
      '2': '02',
      co: '02-Co',
    },
    title: '02-Cortex Agent',
    content: <ArchitectureContent />,
    showInTerminal: true,
  },
  {
    key: '03',
    aliases: {
      '3': '03',
      ag: '03-Ag',
    },
    title: '03-Agent Platform',
    content: <AIContent />,
    showInTerminal: true,
  },
  {
    key: '04',
    aliases: {
      '4': '04',
      po: '04-Po',
    },
    title: '04-Points',
    content: <PointsContent />,
    showInTerminal: true,
  },
  {
    key: '05',
    aliases: {
      '5': '05',
      cl: '05-Claim',
    },
    title: '05-Claim CX (for SYN holders)',
    content: <Link href="/stake" />,
    showInTerminal: true,
  },
]
