import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PasskeyArgType } from '@safe-global/protocol-kit'

type AccountStatus = 'initializing' | 'unconnected' | 'generating' | 'connected'

// TODO: Rename accountStatus (not account_status)
// account_status is for twitter chat

interface UserState {
  performedFirstAction: boolean
  twitterConnected: boolean
  smartAccountMinted: boolean
  accountId: number | null
  safeAddress: string | null
  twitterUsername: string | null
  twitterUserId: string | null
  isFollowingCortex: boolean
  accountStatus: AccountStatus
  selectedPasskey: PasskeyArgType | null
  account_status: {
    enabled: boolean
    reason: string
    follower_count: number
  } | null
}

const initialState: UserState = {
  performedFirstAction: false,
  twitterConnected: false,
  smartAccountMinted: false,
  accountId: null,
  safeAddress: null,
  twitterUsername: null,
  twitterUserId: null,
  isFollowingCortex: false,
  accountStatus: 'unconnected',
  account_status: null,
  selectedPasskey: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPerformedFirstAction: (state, action: PayloadAction<boolean>) => {
      state.performedFirstAction = action.payload
    },
    setTwitterConnected: (state, action: PayloadAction<boolean>) => {
      state.twitterConnected = action.payload
    },
    setSmartAccountMinted: (state, action: PayloadAction<boolean>) => {
      state.smartAccountMinted = action.payload
    },
    setAccountDetails: (
      state,
      action: PayloadAction<{ accountId: number; safeAddress: string }>
    ) => {
      state.accountId = action.payload.accountId
      state.safeAddress = action.payload.safeAddress
      state.smartAccountMinted = true
    },
    setTwitterDetails: (
      state,
      action: PayloadAction<{
        username: string
        userId: string
        isFollowing: boolean
        account_status: {
          enabled: boolean
          reason: string
          follower_count: number
        }
      }>
    ) => {
      state.twitterUsername = action.payload.username
      state.twitterUserId = action.payload.userId
      state.isFollowingCortex = action.payload.isFollowing
      state.account_status = action.payload.account_status
      state.twitterConnected = true
    },
    setIsFollowingCortex: (state, action: PayloadAction<boolean>) => {
      state.isFollowingCortex = action.payload
    },
    updateAccountStatus: (state, action: PayloadAction<AccountStatus>) => {
      state.accountStatus = action.payload
    },
    setSelectedPasskey: (
      state,
      action: PayloadAction<PasskeyArgType | null>
    ) => {
      state.selectedPasskey = action.payload
    },
  },
})

export const {
  setPerformedFirstAction,
  setTwitterConnected,
  setSmartAccountMinted,
  setAccountDetails,
  setTwitterDetails,
  setIsFollowingCortex,
  updateAccountStatus,
  setSelectedPasskey,
} = userSlice.actions

export default userSlice.reducer
