export interface Agent {
  title: string
  agent: string
  description: string
  path: string
  active: boolean
}

export const agents = [
  {
    agent: 'stake',
    title: 'SYN ↔ CX Agent',
    description: 'Stake SYN and mint CX',
    path: 'stake',
    active: true,
  },
  {
    agent: 'intelligent-twitter',
    title: 'X Agents Leaderboard',
    description: 'Compare your crypto IQ & spawn your AI clone',
    path: 'twitter',
    active: true,
  },
  {
    agent: 'cx-1',
    title: 'Cortex Agent',
    description: 'Intelligent generalised trading agent',
    path: 'cx-1',
    active: false,
  },
]
