import { useState } from 'react'

interface RatioProgressBarProps {
  numerator: number
  denominator: number
  color?: 'orange' | 'green' | 'blue'
  needTooltip?: boolean
}

const colorMap = {
  orange: {
    background: 'bg-orange-900',
    bar: 'bg-orange-600',
  },
  green: {
    background: 'bg-green-900',
    bar: 'bg-green-600',
  },
  blue: {
    background: 'bg-cortex-blue/20',
    bar: 'bg-cortex-blue/70',
  },
}

export const RatioProgressBar = ({
  numerator,
  denominator,
  color = 'blue',
  needTooltip = true,
}: RatioProgressBarProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const percentage = (numerator / denominator) * 100
  const barColorBackground = colorMap[color]?.background ?? 'bg-cortex-blue/20'
  const barColor = colorMap[color]?.bar ?? 'bg-cortex-blue/70'

  return (
    <div
      className={`relative w-full h-full flex items-center`}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div className={`w-full rounded ${barColorBackground}`}>
        <div
          className={`h-[2px] rounded transition-all duration-500 ease-out ${barColor}`}
          style={{
            width: `${Math.min(100, percentage)}%`,
          }}
        />
      </div>
      {needTooltip && showTooltip && (
        <div
          className={`
            absolute -top-6 left-1/2 transform -translate-x-1/2
            bg-cortex-object px-2 py-1 rounded text-sm text-white
            border border-white/10 shadow-lg z-10
          `}
        >
          {numerator.toFixed()}
        </div>
      )}
    </div>
  )
}
