export const POINTS_API_URL =
  process.env.NEXT_PUBLIC_POINTS_API_URL || 'https://points-api.omnirpc.io'

export const CIA_API_URL = 'https://cia-api.omnirpc.io'
// export const CIA_API_URL = 'http://localhost:8080'

export const DISCORD_URL = 'https://discord.gg/cortexprotocol'
export const TELEGRAM_URL = 'https://t.me/cortexprotocolofficial'
export const TWITTER_URL =
  'https://x.com/intent/user?screen_name=Cortex_Protocol'
export const MEDIUM_URL = 'https://medium.com/cortex-protocol'
