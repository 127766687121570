export const pointsButtonClassName = (disabled: boolean = false) =>
  [
    'w-fit',
    'p-1 px-1.5 pr-2 -ml-1',
    'text-cortex-strong text-left',
    !disabled
      ? 'hover:dark:bg-white hover:bg-cortex-yellow'
      : 'cursor-not-allowed opacity-50',
    !disabled && 'hover:dark:text-cortex-bg hover:text-cortex-text',
    !disabled && 'focus:dark:bg-white focus:bg-cortex-yellow',
    !disabled && 'focus:dark:text-cortex-bg focus:text-cortex-text',
  ]
    .filter(Boolean)
    .join(' ')
