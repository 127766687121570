export const normalizeForAlias = (value: string): string => {
  const lowerValue = value.toLowerCase()

  if (lowerValue === '1') return '01'
  if (lowerValue === 'ac') return '01-Ac'
  if (lowerValue === '2') return '02'
  if (lowerValue === 'co') return '02-Co'
  if (lowerValue === '3') return '03'
  if (lowerValue === 'ag') return '03-Ag'
  if (lowerValue === '4') return '04'
  if (lowerValue === 'po') return '04-Po'
  if (lowerValue === '5') return '05'
  if (lowerValue === 'cl') return '05-Cl'

  return value
}
