import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { SYN_CORTEX_MULTIPLIER } from '@/constants'
import { findChainByChainId } from '@/utils/findChainByChainId'
import { LOCKDROP_SUPPORTED_CHAINS } from '@/wagmi/supportedChains'
import { formatBalance } from '@/utils/formatBalance'
import { formatNumber } from '@/utils/formatNumber'
import { SUGGESTIONS_API_ENDPOINT } from '@/hooks/useSuggestedResponses'
import {
  type LockdropBalance,
  type LockdropAction,
  type LockdropMessage,
} from '@/types/lockdrop'
import type { RootState } from '@/store'

interface LockdropChatState {
  messages: LockdropMessage[]
  isLoading: boolean
  error: string | null
  suggestedResponses: string[]
  currentStreamedMessage: string | null
}

const initialState: LockdropChatState = {
  messages: [
    {
      content:
        "👋 Welcome! I'm your Cortex AI agent. I can analyze your portfolio and guide you through claiming CX tokens by staking SYN.\n\n To get started, I'll need to check your wallet to see how many CX tokens you're eligible for. Would you like to connect your wallet?",
      role: 'assistant',
      timestamp: new Date().toISOString(),
      action: {
        type: 'connect_wallet',
      },
    },
  ],
  isLoading: false,
  error: null,
  suggestedResponses: ["What's Cortex?", 'Check SYN Balance', 'Stake my SYN'],
  currentStreamedMessage: null,
}

// Utility functions
const hasPositiveBalance = (balances: LockdropBalance[]): boolean =>
  balances.length > 0 && balances.some((b) => BigInt(b.balance) > 0n)

export const createMessage = (
  content: string | null,
  action?: LockdropAction
): LockdropMessage => ({
  role: 'assistant',
  timestamp: new Date().toISOString(),
  content,
  action,
})

export const checkBalanceAndRespond = createAsyncThunk(
  'lockdropChat/checkBalance',
  async (_, { getState }) => {
    const state = getState() as RootState
    const balances = state.lockdropPortfolio.balances
    const hasBalance = hasPositiveBalance(balances)

    return createMessage(
      hasBalance
        ? 'Great! I see you have SYN tokens. Here are your balances'
        : "I don't see any SYN tokens in your wallet. You'll need some SYN tokens to participate in the lockdrop.",
      {
        type: 'balance_check',
        data: {
          hasBalance,
          balances: hasBalance ? balances : undefined,
        },
      }
    )
  }
)

export const respondWithoutAddress = createAsyncThunk(
  'lockdropChat/respondWithoutAddress',
  async (_, { getState }) => {
    const message: LockdropMessage = {
      role: 'assistant',
      timestamp: new Date().toISOString(),
      content: 'Please connect your wallet to continue',
      action: {
        type: 'no_address',
      },
    }

    return message
  }
)

export const respondAboutCortex = createAsyncThunk(
  'lockdropChat/respondAboutCortex',
  async (_, { getState }) => {
    const message: LockdropMessage = {
      role: 'assistant',
      timestamp: new Date().toISOString(),
      content: null,
      action: {
        type: 'whats_cortex',
      },
    }

    return message
  }
)

export const showAnalysisSteps = createAsyncThunk(
  'lockdropChat/showSteps',
  async (_, { getState }) => {
    const state = getState() as RootState
    const balances = state.lockdropPortfolio.balances
    const hasBalance = hasPositiveBalance(balances)

    // Wait briefly before showing steps
    await new Promise((resolve) => setTimeout(resolve, 1000))

    return createMessage('Analyzing your portfolio...', {
      type: 'analysis_steps',
      data: {
        hasBalance: true,
        balances,
      },
    })
  }
)

export const showAnalysisResult = createAsyncThunk(
  'lockdropChat/showResult',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState
    const balances = state.lockdropPortfolio.balances
    const hasBalance = hasPositiveBalance(balances)

    let content: string
    if (!hasBalance) {
      content =
        'I see you have 0 SYN in your wallet. You will need some SYN tokens to mint CX.'
    } else {
      const totalSyn = balances
        .filter((balance) => BigInt(balance.balance) > 0)
        .reduce((acc, bal) => acc + Number(bal.parsedBalance), 0)

      const potentialCx = totalSyn * SYN_CORTEX_MULTIPLIER
      content = `I see you have ${formatNumber(totalSyn)} SYN in your wallet. This means you are eligible for ${formatNumber(potentialCx)} CX. Would you like to mint CX?`
    }

    const message = createMessage(content)
    await dispatch(addAssistantMessage(message))
    return message
  }
)

export const stakeSyn = createAsyncThunk(
  'lockdropChat/stakeSyn',
  async (params: { chainId: number }, { getState }) => {
    const state = getState() as RootState
    const balances = state.lockdropPortfolio.balances

    const lockdropSupportedChainIds: number[] = LOCKDROP_SUPPORTED_CHAINS.map(
      (c) => c.id
    )

    const chain = findChainByChainId(params.chainId)

    const synBalance = Number(
      balances
        .filter((bal) => bal.chainId === params.chainId)
        .find((bal) => bal.symbol === 'SYN')?.parsedBalance
    )

    const mintableCx = synBalance * SYN_CORTEX_MULTIPLIER

    let msg

    if (lockdropSupportedChainIds.includes(params.chainId)) {
      msg = `I've created a transaction to stake your ${formatBalance(synBalance)} SYN on the ${chain.name} chain, and mint ${formatBalance(mintableCx)} CX in its place.`
    } else {
      msg = `The lockdrop is unavailable on ${chain.name}. Please connect to one of ${LOCKDROP_SUPPORTED_CHAINS.map((c) => c.name).join(', ')} to participate.`
    }

    return createMessage(msg, {
      type: 'lock_syn',
      data: {
        chainId: params.chainId,
      },
    })
  }
)

export const unlockCx = createAsyncThunk(
  'lockdropChat/unlockCx',
  async (params: { chainId: number }, { getState }) => {
    const state = getState() as RootState
    const balances = state.lockdropPortfolio.balances

    const lockdropSupportedChainIds: number[] = LOCKDROP_SUPPORTED_CHAINS.map(
      (c) => c.id
    )

    const chain = findChainByChainId(params.chainId)

    const cxBalance = Number(
      balances
        .filter((bal) => bal.chainId === params.chainId)
        .find((bal) => bal.symbol === 'CX')?.parsedBalance
    )

    const returnableSyn = cxBalance / SYN_CORTEX_MULTIPLIER

    let msg

    if (lockdropSupportedChainIds.includes(params.chainId)) {
      msg = `I've created a transaction to release your ${formatBalance(cxBalance)} CX on the ${chain.name} chain, and unstake ${formatBalance(returnableSyn)} SYN in its place.`
    } else {
      msg = `The lockdrop is unavailable on ${chain.name}. Please connect to one of ${LOCKDROP_SUPPORTED_CHAINS.map((c) => c.name).join(', ')} to participate.`
    }

    return createMessage(msg, {
      type: 'unlock_cx',
      data: {
        chainId: params.chainId,
      },
    })
  }
)

export const stakingProgress = createAsyncThunk(
  'lockdropChat/stakingProgress',
  async (
    params: {
      fromSymbol: string
      toSymbol: string
      fromAmount: number
      toAmount: number
      txHash: string
      chainId: number
    },
    { getState }
  ) => {
    let msg

    if (params.fromSymbol === 'SYN') {
      msg = `Staking ${formatBalance(params.fromAmount)} SYN and minting ${formatBalance(params.toAmount)} CX. Please wait a moment for the transaction to complete.`
    } else {
      msg = `Releasing ${formatBalance(params.fromAmount)} CX and unstaking ${formatBalance(params.toAmount)} SYN. Please wait a moment for the transaction to complete.`
    }

    return createMessage(msg, {
      type: 'lockdrop_progress',
      data: {
        fromSymbol: params.fromSymbol,
        toSymbol: params.toSymbol,
        fromAmount: params.fromAmount,
        toAmount: params.toAmount,
        txHash: params.txHash,
        chainId: params.chainId,
      },
    })
  }
)

export const lockingSummary = createAsyncThunk(
  'lockdropChat/lockingSummary',
  async (
    params: {
      fromSymbol: string
      toSymbol: string
      fromAmount: number
      toAmount: number
      txHash: string
      chainId: number
    },
    { getState }
  ) => {
    const chain = findChainByChainId(params.chainId)

    const isLockingTxn = params.toSymbol === 'CX'
    const language = isLockingTxn ? 'minted' : 'unstaked'

    const msg = `${isLockingTxn ? '🎉' : ''} ${formatBalance(params.toAmount)} ${params.toSymbol} ${language} on ${chain?.name}`

    return createMessage(msg, {
      type: 'locking_summary',
      data: {
        fromSymbol: params.fromSymbol,
        toSymbol: params.toSymbol,
        fromAmount: params.fromAmount,
        toAmount: params.toAmount,
        txHash: params.txHash,
        chainId: params.chainId,
      },
    })
  }
)

export const bridgeSyn = createAsyncThunk(
  'lockdropChat/bridgeSyn',
  async (params: { chainId: number }, { getState }) => {
    const state = getState() as RootState
    const balances = state.lockdropPortfolio.balances

    const synBalance = Number(
      balances
        .filter((bal) => bal.chainId === params.chainId)
        .find((bal) => bal.symbol === 'SYN')?.parsedBalance
    )

    const supportedNames = LOCKDROP_SUPPORTED_CHAINS.map((c) => c.name)

    const msg = `Bridge to one of the following chains to mint CX: ${supportedNames.join(', ')}`

    return createMessage(msg, {
      type: 'bridge_syn',
      data: {
        chainId: params.chainId,
      },
    })
  }
)

export const addAssistantMessage = createAsyncThunk(
  'lockdropChat/addAssistantMessage',
  async (message: LockdropMessage, { dispatch, getState }) => {
    dispatch(lockdropChatSlice.actions.addAssistantMessagePending(message))

    try {
      const state = getState() as RootState
      const allMessages = [...state.lockdropChat.messages]

      const response = await fetch(SUGGESTIONS_API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chatHistory: allMessages.map((msg) => ({
            role: msg.role,
            content: msg.content || '',
          })),
        }),
      })

      const data = await response.json()
      dispatch(
        lockdropChatSlice.actions.setSuggestedResponses(
          data.suggested_responses || []
        )
      )
    } catch (error) {
      console.error('Error fetching suggestions:', error)
      dispatch(lockdropChatSlice.actions.setSuggestedResponses([]))
    }
  }
)

// Selectors
export const selectLockdropMessages = (state: RootState) =>
  state.lockdropChat.messages
export const selectLockdropChatLoading = (state: RootState) =>
  state.lockdropChat.isLoading
export const selectLockdropChatError = (state: RootState) =>
  state.lockdropChat.error
export const selectSuggestedResponses = (state: RootState) =>
  state.lockdropChat.suggestedResponses
export const selectMessages = (state: RootState) => state.lockdropChat.messages

const lockdropChatSlice = createSlice({
  name: 'lockdropChat',
  initialState,
  reducers: {
    addUserMessage: (state, action: PayloadAction<string>) => {
      state.messages.push({
        content: action.payload,
        timestamp: new Date().toISOString(),
        role: 'user',
      })
      state.isLoading = true
      state.currentStreamedMessage = null
    },
    addAssistantMessagePending: (
      state,
      action: PayloadAction<LockdropMessage>
    ) => {
      console.log('Adding assistant message:', action.payload)
      state.messages.push(action.payload)
      state.isLoading = false
    },
    updateStreamedMessage: (state, action: PayloadAction<string>) => {
      state.currentStreamedMessage = action.payload
    },
    commitStreamedMessage: (state, action: PayloadAction<LockdropMessage>) => {
      state.messages.push(action.payload)
      state.isLoading = false
      state.currentStreamedMessage = null
    },
    setSuggestedResponses: (state, action: PayloadAction<string[]>) => {
      state.suggestedResponses = action.payload
    },
    clearSuggestedResponses: (state) => {
      state.suggestedResponses = []
    },
    resetChat: (state) => {
      state.messages = []
      state.isLoading = false
      state.error = null
      state.suggestedResponses = initialState.suggestedResponses
      state.currentStreamedMessage = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkBalanceAndRespond.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(checkBalanceAndRespond.fulfilled, (state, action) => {
        state.messages.push(action.payload)
        state.isLoading = false
      })
      .addCase(checkBalanceAndRespond.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred'
        state.isLoading = false
      })
      .addCase(showAnalysisSteps.pending, (state) => {
        state.error = null
      })
      .addCase(showAnalysisSteps.fulfilled, (state, action) => {
        state.messages.push(action.payload)
      })
      .addCase(showAnalysisSteps.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred'
      })
      .addCase(showAnalysisResult.pending, (state) => {
        state.error = null
      })
      .addCase(showAnalysisResult.fulfilled, (state, action) => {
        state.messages.push(action.payload)
      })
      .addCase(showAnalysisResult.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred'
      })
      .addCase(respondAboutCortex.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(respondAboutCortex.fulfilled, (state, action) => {
        state.isLoading = false
        state.messages.push(action.payload)
      })
      .addCase(respondAboutCortex.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'An error occurred'
      })
      .addCase(stakeSyn.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(stakeSyn.fulfilled, (state, action) => {
        state.isLoading = false
        state.messages.push(action.payload)
      })
      .addCase(stakeSyn.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'An error occurred'
      })
      .addCase(unlockCx.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(unlockCx.fulfilled, (state, action) => {
        state.isLoading = false
        state.messages.push(action.payload)
      })
      .addCase(unlockCx.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'An error occurred'
      })
      .addCase(stakingProgress.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(stakingProgress.fulfilled, (state, action) => {
        state.isLoading = false
        state.messages.push(action.payload)
      })
      .addCase(stakingProgress.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'An error occurred'
      })
      .addCase(lockingSummary.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(lockingSummary.fulfilled, (state, action) => {
        state.isLoading = false
        state.messages.push(action.payload)
      })
      .addCase(lockingSummary.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'An error occurred'
      })
      .addCase(bridgeSyn.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(bridgeSyn.fulfilled, (state, action) => {
        state.isLoading = false
        state.messages.push(action.payload)
      })
      .addCase(bridgeSyn.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'An error occurred'
      })
  },
})

export const {
  addUserMessage,
  addAssistantMessagePending,
  updateStreamedMessage,
  commitStreamedMessage,
  setSuggestedResponses,
  clearSuggestedResponses,
  resetChat,
} = lockdropChatSlice.actions
export default lockdropChatSlice.reducer
