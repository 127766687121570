import { createSlice } from '@reduxjs/toolkit'
import { type Address, formatUnits } from 'viem'

import { fetchSynBalances } from '@/store/thunks/fetchSynBalances'
import type { RootState } from '@/store'

interface BalanceResult {
  chainId: number
  address: Address
  balance: string
  parsedBalance: string
  symbol: string
  lockedAmount: string
}

interface LockdropPortfolioState {
  balances: BalanceResult[]
  isLoading: boolean
  error: string | null
}

const initialState: LockdropPortfolioState = {
  balances: [],
  isLoading: false,
  error: null,
}

const lockdropPortfolioSlice = createSlice({
  name: 'lockdropPortfolio',
  initialState,
  reducers: {
    clearBalances: (state) => {
      state.balances = []
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSynBalances.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchSynBalances.fulfilled, (state, action) => {
        state.isLoading = false
        state.balances = action.payload
        state.error = null
      })
      .addCase(fetchSynBalances.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload as string
      })
  },
})

export const selectSynBalances = (state: RootState) =>
  state.lockdropPortfolio.balances
export const selectSynBalancesLoading = (state: RootState) =>
  state.lockdropPortfolio.isLoading
export const selectSynBalancesError = (state: RootState) =>
  state.lockdropPortfolio.error

export const selectTotalSynBalance = (state: RootState) => {
  const totalBigInt = state.lockdropPortfolio.balances.reduce((acc, curr) => {
    return acc + BigInt(curr.balance)
  }, 0n)

  return formatUnits(totalBigInt, 18) // Assuming all SYN tokens use 18 decimals
}

export const { clearBalances } = lockdropPortfolioSlice.actions

export default lockdropPortfolioSlice.reducer
