import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit'

import { CIA_API_URL } from '@/constants/urls'
import {
  type Message,
  type ChatSession,
  type ChatResponse,
  type ResponseData,
  type ChatHistoryResponse,
} from '@/types'
import type { AppDispatch, RootState } from '@/store'

// Message type helpers
type UserMessage = Omit<Message, 'action'> & {
  role: 'user'
}

type AssistantMessage = Message & {
  role: 'assistant'
}

interface ChatState {
  currentSessionId: string
  sessions: ChatSession[]
  messagesBySession: Record<string, Message[]>
  loadingSessions: Record<string, boolean>
  error: string | null
  currentMessage: string
}

export const initialState: ChatState = {
  currentSessionId: '',
  sessions: [],
  messagesBySession: {},
  loadingSessions: {},
  error: null,
  currentMessage: '',
}

/*
TODO: Centralize localStorage Management

Current: localStorage is scattered across components and Redux store, causing SSR issues.
- Direct localStorage calls in chatSlice.ts and components
- No SSR safety checks
- No centralized storage management

Fix:
1. Create storage service with SSR detection
2. Move all localStorage logic there
3. Update Redux to use storage service
4. Remove direct localStorage calls

Files to update:
- chatSlice.ts
- TransactionHistory.tsx
- store/index.ts
*/

export const switchSessionAndFetchHistory = createAsyncThunk<
  ChatHistoryResponse, // Return type
  string, // Argument type
  {
    // ThunkAPI config
    state: { chat: ChatState }
    dispatch: AppDispatch
  }
>(
  'chat/switchSessionAndFetchHistory',
  async (sessionId: string, { dispatch }) => {
    dispatch(switchSession(sessionId))
    const response = await fetch(`${CIA_API_URL}/chat/history`, {
      headers: { 'Session-Id': sessionId },
    })
    if (!response.ok) throw new Error('Failed to fetch chat history')
    return response.json()
  }
)

export const addUserMessage = createAsyncThunk(
  'chat/addUserMessage',
  async (content: string, { getState }) => {
    const state = getState() as RootState
    const sessionId = state.chat.currentSessionId || crypto.randomUUID()

    const message: UserMessage = {
      content,
      role: 'user',
      timestamp: new Date().toISOString(),
    }

    return { message, sessionId }
  }
)

export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async ({ content }: { content: string }, { getState }) => {
    const state = getState() as RootState
    const { externalWalletAddress, smartAccountAddress } = state.wallet

    console.log(externalWalletAddress)

    if (!smartAccountAddress && !externalWalletAddress) {
      throw new Error('Please initialize wallet to continue')
    }

    const response = await fetch(`${CIA_API_URL}/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(state.chat.currentSessionId
          ? { 'Session-Id': state.chat.currentSessionId }
          : {}),
        'Wallet-Address': smartAccountAddress ?? externalWalletAddress,
      } as any,
      body: JSON.stringify({ message: content }),
    })

    if (!response.ok) {
      throw new Error('Failed to send message')
    }

    const data: ResponseData = await response.json()
    const message: AssistantMessage = {
      content: data.response,
      role: 'assistant',
      timestamp: new Date().toISOString(),
      action: data.action,
    }

    return {
      message,
      sessionId: data.session_id || state.chat.currentSessionId,
    }
  }
)

// Memoized selectors
export const selectCurrentSessionId = (state: RootState) =>
  state.chat.currentSessionId
export const selectMessagesBySession = (state: RootState) =>
  state.chat.messagesBySession
export const selectSessions = (state: RootState) => state.chat.sessions

export const selectCurrentSessionMessages = createSelector(
  [selectCurrentSessionId, selectMessagesBySession],
  (sessionId, messagesBySession) =>
    sessionId ? messagesBySession[sessionId] || [] : []
)

export const selectChatState = createSelector(
  [(state: RootState) => state.chat],
  (chat) => ({
    isWaitingForResponse: chat.currentSessionId
      ? chat.loadingSessions[chat.currentSessionId]
      : false,
    error: chat.error,
    currentMessage: chat.currentMessage,
  })
)

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    createNewSession: (state) => {
      state.currentSessionId = ''
      state.currentMessage = ''
    },
    switchSession: (state, action: PayloadAction<string>) => {
      state.currentSessionId = action.payload
    },
    clearError: (state) => {
      state.error = null
    },
    setCurrentMessage: (state, action: PayloadAction<string>) => {
      state.currentMessage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUserMessage.fulfilled, (state, action) => {
        const { sessionId, message } = action.payload

        // Create new session if needed
        if (!state.currentSessionId) {
          state.currentSessionId = sessionId
          state.sessions.unshift({
            id: sessionId,
            title:
              message.content.slice(0, 30) +
              (message.content.length > 30 ? '...' : ''),
            timestamp: message.timestamp,
          })
        }

        // Initialize message array if needed
        if (!state.messagesBySession[sessionId]) {
          state.messagesBySession[sessionId] = []
        }

        state.messagesBySession[sessionId].push(message)
      })
      .addCase(sendMessage.pending, (state, action) => {
        const sessionId = state.currentSessionId
        if (sessionId) {
          // Set loading state only for the session making the request
          state.loadingSessions = {
            ...state.loadingSessions,
            [sessionId]: true,
          }
        }
        state.error = null
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        const { sessionId, message } = action.payload

        // Initialize message array if needed
        if (!state.messagesBySession[sessionId]) {
          state.messagesBySession[sessionId] = []
        }

        state.messagesBySession[sessionId].push(message)
        // Clear loading state only for this session
        state.loadingSessions = {
          ...state.loadingSessions,
          [sessionId]: false,
        }
      })
      .addCase(sendMessage.rejected, (state, action) => {
        const sessionId = state.currentSessionId
        if (sessionId) {
          // Clear loading state only for this session
          state.loadingSessions = {
            ...state.loadingSessions,
            [sessionId]: false,
          }
        }
        state.error = action.error.message || 'An error occurred'
      })
      .addCase(switchSessionAndFetchHistory.pending, (state, action) => {
        // Immediately switch to the new session
        const newSessionId = action.meta.arg
        state.currentSessionId = newSessionId
      })
      .addCase(switchSessionAndFetchHistory.fulfilled, (state, action) => {
        const { messages } = action.payload
        // Update messages for current session only
        if (state.currentSessionId && messages) {
          state.messagesBySession[state.currentSessionId] = messages
        }
      })
      .addCase(switchSessionAndFetchHistory.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch chat history'
      })
  },
})

export const {
  createNewSession,
  switchSession,
  clearError,
  setCurrentMessage,
} = chatSlice.actions
export default chatSlice.reducer
