export const AIContent = () => {
  return (
    <div className="space-y-4 divide-y [&>section]:border-white/10 [&>section]:space-y-2.5 [&>section]:pt-6 [&>section:first-child]:pt-1">
      <section>
        <h2 className="text-lg text-cortex-strong/90">Agent Platform</h2>
        <p>
          Cortex's agent platform allows developers to permissionlessly deploy
          agents with on-chain capabilities.
          <br />
          <br />
          Each agent is built with its own data sources, on-chain constraints
          and objective function.
        </p>
        <ul className="py-0.5">
          Example agents to build on Cortex Protocol:
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            Yield Farming Agent - Autonomously farms highest yielding pools,
            auto-compounds, and rebalances across chains
          </li>
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            Ethena Agent - Optimizes yield and sats based on user valuation
            preferences
          </li>
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            Alpha Generator - Creates trading bots using on-chain data, Arkham,
            and other sources
          </li>
        </ul>
      </section>
      <section>
        <h3 className="text-cortex-yellow">Agent-First Deployments</h3>
        <ul className="py-0.5">
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            Chain Deployment - Launch chains with agents trained on chain
            architecture
          </li>
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            Protocol Deployment - Deploy protocols with agents trained on docs
            and contracts
          </li>
        </ul>
      </section>
    </div>
  )
}
