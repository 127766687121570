'use client'

import Link from 'next/link'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from '@/store'
import { LoginWithPasskey } from '@/components/Teaser/LoginWithPasskey'
import { LoginWithX } from '@/components/Teaser/LoginWithX'
import { useCortexAccount } from '@/hooks/useCortexAccount'
import { setSelectedAndOverwrite } from '@/store/slices/selectedOptionsSlice'
import { TextLoader } from '@/components/ui/TextLoader'

import { SafeAccount } from './SafeAccount'
import { InitializingTextLoader } from './InitializingTextLoader'

export const AccountsContent = () => {
  const dispatch = useDispatch()

  const {
    smartAccountMinted,
    twitterConnected,
    accountId,
    selectedPasskey,
    accountStatus,
  } = useSelector((state: RootState) => state.user)

  const {
    checkForExistingAccount,
    getSafeInfo,
    handleCreatePasskey,
    handleSelectPasskey,
  } = useCortexAccount()

  useEffect(() => {
    checkForExistingAccount()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedPasskey && accountId) {
      getSafeInfo(selectedPasskey, accountId)
    }
  }, [selectedPasskey, accountId, getSafeInfo])

  return (
    <div className="space-y-4 divide-y [&>section]:border-white/10 [&>section]:space-y-2.5 [&>section]:pt-6 [&>section:first-child]:pt-1">
      <section>
        <h3 className="text-lg text-cortex-strong/90">
          Mint your Cortex Intelligent Account
        </h3>
        <ul>
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            One wallet for every chain
          </li>
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            Unified gas and asset balances on every chain
          </li>
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            Simple creation and use with passkeys or Face&nbsp;ID
          </li>
        </ul>
      </section>
      <section className="space-y-3 pt-4">
        {accountStatus === 'unconnected' && (
          <>
            <h3 className="text-lg">Demo</h3>
            <LoginWithPasskey
              handleCreatePasskey={handleCreatePasskey}
              handleSelectPasskey={handleSelectPasskey}
            />
          </>
        )}

        {accountStatus === 'initializing' && (
          <InitializingTextLoader text="Looking up Cortex ID" />
        )}

        {accountStatus === 'generating' && <TextLoader />}

        {accountStatus === 'connected' && <SafeAccount />}

        <p className="tracking-wider text-white/65 text-[0.8em] space-y-1.5 my-6 block">
          <p>Note: Cortex Intelligent Accounts are in development beta 1.01</p>
          <p>
            Users who mint their Cortex Intelligent Account and connect their X
            account will receive Cortex points once launched.
          </p>
        </p>
      </section>

      {smartAccountMinted && (
        <section>
          {twitterConnected ? (
            <Link
              href="#"
              className="
                  p-1 px-1.5 pr-2 rounded-sm text-left-ml-1
                  hover:dark:bg-white hover:bg-cortex-yellow
                  hover:dark:text-cortex-bg hover:text-cortex-text
                  focus:dark:bg-white focus:bg-cortex-yellow
                  focus:dark:text-cortex-bg focus:text-cortex-text focus:outline-none
                  before:content-['•'] before:opacity-0 before:mr-1.5
                  hover:before:opacity-100 focus:before:opacity-100
              "
              onClick={() => dispatch(setSelectedAndOverwrite('04'))}
            >
              Next: Secure the network
            </Link>
          ) : (
            <>
              <h3 className="text-lg">Next: Follow @Cortex_Protocol on X</h3>
              <LoginWithX initialTicketId={accountId} />
            </>
          )}
        </section>
      )}
    </div>
  )
}
