import { Transport, type Chain } from 'viem'
import { fallback, http } from '@wagmi/core'

import { findChainByChainId } from '@/utils/findChainByChainId'

type Transports = Record<Chain['id'], Transport>

export const createTransports = (chains: Chain[]): Transports => {
  return chains.reduce<Transports>((acc, chain) => {
    const cortexChain = findChainByChainId(chain.id)

    acc[chain.id] = fallback([
      http(cortexChain.rpcUrls.primary),
      http(cortexChain.rpcUrls.fallback),
    ])
    return acc
  }, {})
}
