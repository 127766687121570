import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Transaction {
  hash: string
  type: 'swap' | 'bridge'
  fromToken: string
  toToken: string
  fromAmount: string
  toAmount: string
  originChainId: number
  destChainId?: number
  status: 'pending' | 'confirmed' | 'failed'
  timestamp: number
}

interface AddressTransactions {
  transactions: Transaction[]
}

interface TransactionsState {
  [address: string]: AddressTransactions
}

const initialState: TransactionsState = {}

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    addTransaction: (
      state,
      action: PayloadAction<{
        address: string
        transaction: Omit<Transaction, 'timestamp'>
      }>
    ) => {
      const { address, transaction } = action.payload

      const newTransaction = {
        ...transaction,
        timestamp: Date.now(),
      }

      return {
        ...state,
        [address]: {
          transactions: state[address]
            ? [...state[address].transactions, newTransaction]
            : [newTransaction],
        },
      }
    },
  },
})

export const { addTransaction } = transactionsSlice.actions
export default transactionsSlice.reducer
