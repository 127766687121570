interface ActionButtonProps {
  onClick?: () => void
  type?: 'button' | 'submit'
  children: React.ReactNode
  disabled?: boolean
  underline?: boolean
  linethrough?: boolean
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  type = 'button',
  children,
  disabled = false,
  underline = true,
  linethrough = false,
}) => {
  const buttonClassName = `
    dark:text-cortex-yellow
    hover:dark:bg-white hover:bg-cortex-yellow
    hover:dark:text-cortex-bg hover:text-cortex-text
    -mx-1 px-1 rounded-sm
    disabled:opacity-50 disabled:hover:dark:bg-transparent disabled:hover:dark:text-cortex-yellow
  ${underline ? 'underline' : ''}
  ${linethrough ? 'line-through' : ''}
  `

  return (
    <button
      type={type}
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
