export const LiveCircle = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="-10 -10 20 20"
      strokeOpacity=".25"
      overflow="visible"
      className="fill-green-400 stroke-green-400"
    >
      <circle r="4">
        <animate
          attributeName="stroke-width"
          begin="0s"
          dur="2s"
          values="4; 20"
          repeatCount="indefinite"
          calcMode="spline"
          keyTimes="0; 1"
          keySplines="0 0 .2 1"
        />
        <animate
          attributeName="stroke-opacity"
          begin="0s"
          dur="2s"
          to="0"
          repeatCount="indefinite"
          calcMode="spline"
          keyTimes="0; 1"
          keySplines="0 0 .5 1"
        />
      </circle>
    </svg>
  )
}
