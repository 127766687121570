import React from 'react'
import Link from 'next/link'

import { DiscordIcon } from '@/components/ui/icons/DiscordIcon'
import { TelegramIcon } from '@/components/ui/icons/TelegramIcon'
import { TwitterIcon } from '@/components/ui/icons/TwitterIcon'
import { MediumIcon } from '@/components/ui/icons/MediumIcon'
import {
  MEDIUM_URL,
  DISCORD_URL,
  TELEGRAM_URL,
  TWITTER_URL,
} from '@/constants/urls'

export const SocialIcons = () => {
  return (
    <ul className="flex gap-2.5 translate-y-1">
      <li>
        <Link href={DISCORD_URL} target="_blank">
          <DiscordIcon className="fill-current text-cortex-text/80 hover:text-cortex-text/100 transition-all w-7 h-7" />
        </Link>
      </li>
      <li>
        <Link href={TELEGRAM_URL} target="_blank">
          <TelegramIcon className="fill-current text-cortex-text/80 hover:text-cortex-text/100 transition-all w-7 h-7" />
        </Link>
      </li>
      <li>
        <Link href={TWITTER_URL} target="_blank">
          <TwitterIcon className="fill-current text-cortex-text/80 hover:text-cortex-text/100 transition-all w-7 h-7" />
        </Link>
      </li>
      <li>
        <Link href={MEDIUM_URL} target="_blank">
          <MediumIcon className="fill-current text-cortex-text/80 hover:text-cortex-text/100 transition-all w-7 h-7" />
        </Link>
      </li>
    </ul>
  )
}
