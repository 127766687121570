import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/navigation'

import { type Agent, agents } from '@/constants/agents'
import { useTotalCortexAccounts } from '@/hooks/useTotalCortexAccounts'
import { type RootState } from '@/store'
import { useCortexAccount } from '@/hooks/useCortexAccount'

import { LiveCircle } from '../Lockdrop/LiveCircle'
import { StakingProgressSection } from '../Lockdrop/StakingProgressSection'

const agentsOverviewClass = (showOverview: boolean) => `
  bg-cortex-swimlane p-3 border border-white/10 rounded-md
  hover:cursor-pointer hover:border-white/25
  h-fit w-full lg:w-[350px] overflow-hidden
  ${showOverview ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-full'}
`

export const StakingAgentOverview = () => {
  const router = useRouter()
  const [showOverview, setShowOverview] = useState(false)

  const { accountId, twitterUserId } = useSelector(
    (state: RootState) => state.user
  )
  const { fetchTotalAccounts } = useTotalCortexAccounts()

  const { checkForExistingAccount } = useCortexAccount()

  const agent = agents.find((a) => a.path === 'stake') as Agent

  useEffect(() => {
    checkForExistingAccount()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowOverview(true)
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  const associatedId = Number(twitterUserId) || Number(accountId)

  useEffect(() => {
    if (associatedId) {
      fetchTotalAccounts()
    }
  }, [associatedId]) // eslint-disable-line react-hooks/exhaustive-deps

  const className = agentsOverviewClass(showOverview)

  return (
    <div
      className={className}
      onClick={() => router.push(`/agents/${agent.path}`)}
    >
      <Overview agent={agent} />
      <StakingProgressSection />
    </div>
  )
}

export const TwitterAgentOverview = () => {
  const router = useRouter()
  const [showOverview, setShowOverview] = useState(false)

  const agent = agents.find((a) => a.path === 'twitter') as Agent

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowOverview(true)
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  const className = agentsOverviewClass(showOverview)

  return (
    <div
      className={className}
      onClick={() => router.push(`/agents/${agent.path}`)}
    >
      <Overview agent={agent} />
    </div>
  )
}

const Overview = ({ agent }: { agent: Agent }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="w-full">
        <div className="flex items-center justify-between">
          <div>{agent.title}</div>
          <div className="flex items-center gap-1">
            <LiveCircle />
            <div className="text-green-400">Live</div>
          </div>
        </div>
        <div className="text-xs text-cortex-text/75">{agent.description}</div>
      </div>
    </div>
  )
}
