'use client'

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  StakingAgentOverview,
  TwitterAgentOverview,
} from '@/components/Teaser/AgentsOverview'
import { LogoFooter } from '@/components/Teaser/LogoFooter'
import { SelectedOptions } from '@/components/Teaser/SelectedOptions'
import { TerminalDisplay } from '@/components/Teaser/TerminalDisplay'

const Home = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const getQueryParam = (param: string) => {
      if (typeof window === 'undefined') return null
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get(param)
    }

    const sessionToken = getQueryParam('session-token')

    if (sessionToken) {
      window.close()
    }
  }, [dispatch])

  return (
    <main className="relative min-h-dvh p-2 pb-4 sm:p-4 2xl:p-8 flex flex-col gap-4 transition-[padding] bg-grid-pattern bg-grid-pattern-position">
      <div className="grow grid grid-rows-[1fr_auto] xl:grid-cols-[1fr_auto] max-sm:flex-col sm:flex-wrap gap-4 sm:content-between">
        <div className="grow grid grid-rows-[auto_1fr] lg:grid-cols-2 gap-4 max-w-5xl sm:h-fit">
          <TerminalDisplay />
          <SelectedOptions />
        </div>
        <div className="sm:grow flex place-content-end">
          <div className="w-full md:w-auto space-y-3">
            <TwitterAgentOverview />
            <StakingAgentOverview />
          </div>
        </div>
      </div>
      <LogoFooter />
    </main>
  )
}

export default Home
