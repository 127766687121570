import {
  arbitrum,
  bsc,
  mainnet,
  avalanche,
  canto,
  optimism,
  polygon,
  fantom,
  cronos,
  boba,
  metis,
  aurora,
  moonbeam,
  base,
  scroll,
  linea,
  dfk,
  klaytn,
  harmonyOne,
  moonriver,
  dogechain,
  blast,
  worldchain,
} from 'viem/chains'

import { CortexChainConfig } from '@/types'

export const CHAINS: CortexChainConfig[] = [
  {
    chainId: 1,
    name: 'Ethereum',
    viemNetwork: mainnet,
    rpcUrls: {
      primary:
        'https://eth-mainnet.g.alchemy.com/v2/rJ3f0IWjZbpgEwnzrRS6yYO3WNH0jGle',
      fallback: 'https://eth.llamarpc.com',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/ethereum.4a372106.svg',
  },
  {
    chainId: 42161,
    name: 'Arbitrum',
    viemNetwork: arbitrum,
    rpcUrls: {
      primary:
        'https://arb-mainnet.g.alchemy.com/v2/7kjdkqKTh1zQ1mRYGi4nJJbxbyJXHkef',
      fallback: 'https://arb1.arbitrum.io/rpc',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/arbitrum.8ddb1b22.svg',
  },
  {
    chainId: 56,
    name: 'BNB Chain',
    viemNetwork: bsc,
    rpcUrls: {
      primary: 'https://bsc-dataseed1.ninicoin.io/',
      fallback: 'https://bsc-dataseed2.ninicoin.io',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/bnb.5948fe5e.svg',
  },
  {
    chainId: 43114,
    name: 'Avalanche',
    viemNetwork: avalanche,
    rpcUrls: {
      primary: 'https://api.avax.network/ext/bc/C/rpc',
      fallback: 'https://1rpc.io/avax/c',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/avalanche.9d53cbf0.svg',
  },
  {
    chainId: 7700,
    name: 'Canto',
    viemNetwork: canto,
    rpcUrls: {
      primary: 'https://mainnode.plexnode.org:8545',
      fallback: 'https://canto.slingshot.finance',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/canto.cb85e14f.svg',
  },
  {
    chainId: 10,
    name: 'Optimism',
    viemNetwork: optimism,
    rpcUrls: {
      primary:
        'https://opt-mainnet.g.alchemy.com/v2/x1--EvECmiLCc4IRpo1granp1S7xxbxQ',
      fallback: 'https://gateway.tenderly.co/public/optimism',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/optimism.84d4f0ef.svg',
  },
  {
    chainId: 137,
    name: 'Polygon',
    viemNetwork: polygon,
    rpcUrls: {
      primary:
        'https://polygon-mainnet.g.alchemy.com/v2/mN1t8Oc6E912QF28iPHaRvVEmv6EpYSs',
      fallback: 'https://polygon.llamarpc.com',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/polygon.237cd2b6.svg',
  },
  {
    chainId: 53935,
    name: 'DFK Chain',
    viemNetwork: dfk,
    rpcUrls: {
      primary: 'https://subnets.avax.network/defi-kingdoms/dfk-chain/rpc',
      fallback: 'https://dfkchain.rpc.defikingdoms.com/api=654302102',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/dfk.2bd1f0e4.svg',
  },
  {
    chainId: 8217,
    name: 'Klaytn',
    viemNetwork: klaytn,
    rpcUrls: {
      primary: 'https://kaia.blockpi.network/v1/rpc/public',
      fallback: 'https://internal.klaytn.rpc.defikingdoms.com/api=654302102',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/klaytn.59495fbb.svg',
  },
  {
    chainId: 250,
    name: 'Fantom',
    viemNetwork: fantom,
    rpcUrls: {
      primary: 'https://rpc.ftm.tools',
      fallback: 'https://fantom-rpc.gateway.pokt.network/',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/fantom.1e444dad.svg',
  },
  {
    chainId: 25,
    name: 'Cronos',
    viemNetwork: cronos,
    rpcUrls: {
      primary: 'https://evm-cronos.crypto.org',
      fallback: 'https://cronos.blockpi.network/v1/rpc/public',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/cronos.b06f8311.svg',
  },
  {
    chainId: 288,
    name: 'Boba Chain',
    viemNetwork: boba,
    rpcUrls: {
      primary: 'https://mainnet.boba.network',
      fallback: 'https://replica.boba.network',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/boba.2072e50b.svg',
  },
  {
    chainId: 1088,
    name: 'Metis',
    viemNetwork: metis,
    rpcUrls: {
      primary: 'https://andromeda.metis.io/?owner=1088',
      fallback: 'https://metis-mainnet.public.blastapi.io',
    },
    imgUrl:
      'https://7bdf4de3.sanguine-fe.pages.dev/_next/static/media/metis.90b6abf0.svg',
  },
  {
    chainId: 1313161554,
    name: 'Aurora',
    viemNetwork: aurora,
    rpcUrls: {
      primary: 'https://mainnet.aurora.dev',
      fallback: 'https://1rpc.io/aurora',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/aurora.5a46037d.svg',
  },
  {
    chainId: 1666600000,
    name: 'Harmony',
    viemNetwork: harmonyOne,
    rpcUrls: {
      primary: 'https://api.harmony.one',
      fallback: 'https://api.s0.t.hmny.io',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/harmony.af12f77e.svg',
  },
  {
    chainId: 1284,
    name: 'Moonbeam',
    viemNetwork: moonbeam,
    rpcUrls: {
      primary: 'https://rpc.api.moonbeam.network',
      fallback: 'https://moonbeam.public.blastapi.io',
    },
    imgUrl:
      'https://adf1cceb.sanguine-fe.pages.dev/_next/static/media/moonbeam.63f34507.svg',
  },
  {
    chainId: 1285,
    name: 'Moonriver',
    viemNetwork: moonriver,
    rpcUrls: {
      primary: 'https://rpc.api.moonriver.moonbeam.network',
      fallback: 'https://moonriver.public.blastapi.io',
    },
    imgUrl:
      'https://adf1cceb.sanguine-fe.pages.dev/_next/static/media/moonriver.275d190a.svg',
  },
  {
    chainId: 2000,
    name: 'Dogechain',
    viemNetwork: dogechain,
    rpcUrls: {
      primary: 'https://rpc.dogechain.dog',
      fallback: 'https://rpc01-sg.dogechain.dog',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/dogechain.36935650.svg',
  },
  {
    chainId: 8453,
    name: 'Base',
    viemNetwork: base,
    rpcUrls: {
      primary:
        'https://base-mainnet.g.alchemy.com/v2/_YKy-Vm3LsknT8JKSa2ZTSmKu9Qp01Vd',
      fallback: 'https://developer-access-mainnet.base.org',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/base.d919fbef.svg',
  },
  {
    chainId: 81457,
    name: 'Blast',
    viemNetwork: blast,
    rpcUrls: {
      primary:
        'https://lingering-indulgent-replica.blast-mainnet.quiknode.pro/6667a8f4be701cb6549b415d567bc706fb2f13a8/',
      fallback: 'https://blast.blockpi.network/v1/rpc/publicChain',
    },
    imgUrl:
      'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/blast.e39807f8.svg',
  },
  {
    chainId: 534352,
    name: 'Scroll',
    viemNetwork: scroll,
    rpcUrls: {
      primary: 'https://rpc.scroll.io/',
      fallback: 'https://scroll.blockpi.network/v1/rpc/public',
    },
    imgUrl:
      'https://fe-adds-scroll.sanguine-fe.pages.dev/_next/static/media/scroll.a805c122.svg',
  },
  {
    chainId: 59144,
    name: 'Linea',
    viemNetwork: linea,
    rpcUrls: {
      primary: 'https://rpc.linea.build',
      fallback: 'https://linea.blockpi.network/v1/rpc/public',
    },
    imgUrl:
      'https://master.sanguine-fe.pages.dev/_next/static/media/linea.e476f2ad.svg',
  },
  {
    chainId: 480,
    name: 'World Chain',
    viemNetwork: worldchain,
    rpcUrls: {
      primary:
        'https://worldchain-mainnet.g.alchemy.com/v2/kThJADa4Pb6x6kTS--MoMQRI7d3OliUH',
      fallback: 'https://worldchain-mainnet.g.alchemy.com/public',
    },
    imgUrl:
      'https://synapse-interface-worldchain.sanguine-fe.pages.dev/_next/static/media/worldchain.62d1dfd2.svg',
  },
]
