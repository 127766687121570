import { useEffect, useState } from 'react'

export const InitializingTextLoader = ({
  text = 'Loading',
}: {
  text?: string
}) => {
  const [dots, setDots] = useState('')

  useEffect(() => {
    const dotInterval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''))
    }, 375)

    return () => clearInterval(dotInterval)
  }, [])

  return (
    <div className="text-cortex-yellow pt-3">
      {text}
      {dots}
    </div>
  )
}
