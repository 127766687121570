import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface WalletState {
  address: string | null
  externalWalletAddress?: string
  embeddedWalletAddress?: string
  smartAccountAddress?: string
  error: string | null
}

const initialState: WalletState = {
  address: null,
  externalWalletAddress: undefined,
  embeddedWalletAddress: undefined,
  smartAccountAddress: undefined,
  error: null,
}

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setExternalWalletAddress: (state, action: PayloadAction<string>) => {
      state.externalWalletAddress = action.payload
    },
    setEmbeddedWalletAddress: (state, action: PayloadAction<string>) => {
      state.embeddedWalletAddress = action.payload
    },
    setSmartAccountAddress: (state, action: PayloadAction<string>) => {
      state.smartAccountAddress = action.payload
    },
    clearAddresses: (state) => {
      state.externalWalletAddress = undefined
      state.embeddedWalletAddress = undefined
      state.smartAccountAddress = undefined
    },
    clearError: (state) => {
      state.error = null
    },
  },
})

export const {
  setEmbeddedWalletAddress,
  setExternalWalletAddress,
  setSmartAccountAddress,
  clearAddresses,
  clearError,
} = walletSlice.actions

export default walletSlice.reducer
