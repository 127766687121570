export const formatNumber = (num: number): string => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(2)}M`
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(2)}K`
  }
  if (num < 0.0001) {
    return num.toExponential(2)
  }
  // For regular numbers, use different decimal places based on size
  if (num >= 1) {
    return num.toFixed(2)
  }
  return num.toFixed(4)
}
