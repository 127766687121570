import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { fetchPersonalityAnalysis } from '../thunks/fetchPersonalityAnalysis'
import { fetchAdditionalAnalysis } from '../thunks/fetchAdditionalAnalysis'

interface TwitterState {
  loadingTweets: boolean
  personalityAnalysis: {
    loading: boolean
    data: {
      overall_crypto_iq: number
      degeneracy: number
      intellect: number
      wealth: number
    } | null
    error: string | null
  }
  additionalAnalysis: {
    loading: boolean
    data: {
      favorite_tokens: string[]
      trading_style: string
      personality_summary: string
      friendly_roast: string
    } | null
    error: string | null
  }
  generatedTweets: string[]
}

const initialState: TwitterState = {
  loadingTweets: false,
  personalityAnalysis: {
    loading: false,
    data: null,
    error: null,
  },
  additionalAnalysis: {
    loading: false,
    data: null,
    error: null,
  },
  generatedTweets: [],
}

const twitterSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    setGeneratedTweets: (state, action: PayloadAction<string[]>) => {
      state.generatedTweets = action.payload
    },
    setLoadingTweets: (state, action: PayloadAction<boolean>) => {
      state.loadingTweets = action.payload
    },
    clearGeneratedTweets: (state) => {
      state.generatedTweets = initialState.generatedTweets
    },
  },
  extraReducers: (builder) => {
    builder
      // Personality Analysis
      .addCase(fetchPersonalityAnalysis.pending, (state) => {
        state.personalityAnalysis.loading = true
        state.personalityAnalysis.error = null
      })
      .addCase(fetchPersonalityAnalysis.fulfilled, (state, action) => {
        state.personalityAnalysis.loading = false
        state.personalityAnalysis.data = action.payload
        state.personalityAnalysis.error = null
      })
      .addCase(fetchPersonalityAnalysis.rejected, (state, action) => {
        state.personalityAnalysis.loading = false
        state.personalityAnalysis.error = action.payload as string
      })
      // Additional Analysis
      .addCase(fetchAdditionalAnalysis.pending, (state) => {
        state.additionalAnalysis.loading = true
        state.additionalAnalysis.error = null
      })
      .addCase(fetchAdditionalAnalysis.fulfilled, (state, action) => {
        state.additionalAnalysis.loading = false
        state.additionalAnalysis.data = action.payload
        state.additionalAnalysis.error = null
      })
      .addCase(fetchAdditionalAnalysis.rejected, (state, action) => {
        state.additionalAnalysis.loading = false
        state.additionalAnalysis.error = action.payload as string
      })
  },
})

export const { setGeneratedTweets, setLoadingTweets, clearGeneratedTweets } =
  twitterSlice.actions

export default twitterSlice.reducer
