import { configureStore } from '@reduxjs/toolkit'

import { navigationMiddleware } from '@/middleware/navigationMiddleware'
import selectedOptionsReducer from '@/store/slices/selectedOptionsSlice'
import userReducer from '@/store/slices/userSlice'
import terminalReducer from '@/store/slices/terminalSlice'
import transactionsReducer from '@/store/slices/transactionsSlice'
import lockdropPortfolioReducer from '@/store/slices/lockdropPortfolioSlice'
import lockdropChatSliceReducer from '@/store/slices/lockdropChatSlice'
import twitterReducer from '@/store/slices/twitterSlice'
import twitterChatReducer from '@/store/slices/twitterChatSlice'

import portfolioReducer from './slices/portfolioSlice'
import chatReducer, {
  initialState as chatInitialState,
} from './slices/chatSlice'
import walletReducer from './slices/walletSlice'

const loadState = () => {
  try {
    const savedTransactions = localStorage.getItem('transactions')
    const savedSessionId = localStorage.getItem('chat_session_id')

    return {
      transactions: savedTransactions
        ? JSON.parse(savedTransactions)
        : undefined,
      chat: {
        ...chatInitialState,
        currentSessionId: savedSessionId || '',
        messagesBySession: {},
        sessions: [],
      },
    }
  } catch (err) {
    return undefined
  }
}

export const store = configureStore({
  reducer: {
    terminal: terminalReducer,
    user: userReducer,
    selectedOptions: selectedOptionsReducer,
    transactions: transactionsReducer,
    portfolio: portfolioReducer,
    chat: chatReducer,
    wallet: walletReducer,
    lockdropPortfolio: lockdropPortfolioReducer,
    lockdropChat: lockdropChatSliceReducer,
    twitter: twitterReducer,
    twitterChat: twitterChatReducer,
  },
  preloadedState: loadState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(navigationMiddleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
