import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@/store'

const initialLines = [
  'Cortex Protocol',
  'Decentralized AI agents; powered by $CX',
  'AI-powered actions on any chain',
  'For billions of users on the next generation of crypto apps.',
  'The future of crypto is agent-first.',
]

export const useTerminalState = () => {
  const [displayedLines, setDisplayedLines] = useState<string[]>([])
  const [currentLine, setCurrentLine] = useState<string>('')
  const [lineIndex, setLineIndex] = useState<number>(0)
  const [charIndex, setCharIndex] = useState<number>(0)
  const [animationState, setAnimationState] = useState<
    'initial' | 'typing' | 'complete' | 'removing'
  >('initial')
  const [skipAnimation, setSkipAnimation] = useState<boolean>(false)

  const { loadTerminalImmediately } = useSelector(
    (state: RootState) => state.terminal
  )

  const INITIAL_DELAY = 1000
  const TYPING_COMPLETE_DELAY = 3000 // Increased from 1000 to 3000
  const REMOVAL_DURATION = 1000 // New constant for removal animation duration
  const MIN_SPEED = 45
  const MAX_SPEED = 25

  useEffect(() => {
    const handleSkip = () => setSkipAnimation(true)

    document.addEventListener('click', handleSkip)
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') handleSkip()
    })

    return () => {
      document.removeEventListener('click', handleSkip)
      document.removeEventListener('keydown', (e) => {
        if (e.key === 'Enter') handleSkip()
      })
    }
  }, [])

  useEffect(() => {
    if (skipAnimation) {
      setDisplayedLines([])
      setCurrentLine('')
      setAnimationState('removing')
    }

    if (animationState === 'initial') {
      const initialDelay = loadTerminalImmediately
        ? INITIAL_DELAY / 10
        : INITIAL_DELAY
      const timer = setTimeout(() => {
        setAnimationState('typing')
        setCharIndex(1)
      }, initialDelay)
      return () => clearTimeout(timer)
    }

    if (animationState === 'typing' && lineIndex < initialLines.length) {
      const timer = setTimeout(
        () => {
          if (charIndex < initialLines[lineIndex].length) {
            setCurrentLine(initialLines[lineIndex].substring(0, charIndex + 1))
            setCharIndex(charIndex + 1)
          } else {
            setDisplayedLines((prev) => [...prev, currentLine])
            setCurrentLine('')
            if (lineIndex === initialLines.length - 1) {
              setAnimationState('complete')
            } else {
              setLineIndex(lineIndex + 1)
              setCharIndex(0)
            }
          }
        },
        Math.max(
          MAX_SPEED,
          MIN_SPEED - charIndex / 2 + (10 - Math.random() * 30)
        )
      )
      return () => clearTimeout(timer)
    }

    if (animationState === 'complete') {
      const timer = setTimeout(() => {
        setAnimationState('removing')
      }, TYPING_COMPLETE_DELAY)
      return () => clearTimeout(timer)
    }
    if (animationState === 'removing') {
      const timer = setTimeout(() => {
        setDisplayedLines([])
      }, REMOVAL_DURATION)
      return () => clearTimeout(timer)
    }
  }, [
    animationState,
    charIndex,
    currentLine,
    lineIndex,
    skipAnimation,
    loadTerminalImmediately,
  ])

  return {
    displayedLines,
    currentLine,
    animationState,
    isInitialLinesComplete:
      animationState === 'complete' || animationState === 'removing',
  }
}
