import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { type LockdropMessage } from '@/types/lockdrop'

interface twitterChatState {
  messages: LockdropMessage[]
  isLoading: boolean
  error: string | null
}

const initialState: twitterChatState = {
  messages: [
    {
      content: '',
      role: 'assistant',
      timestamp: new Date().toISOString(),
      action: {
        type: 'connect_twitter',
      },
    },
  ],
  isLoading: false,
  error: null,
}

const twitterChatSlice = createSlice({
  name: 'twitterChat',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    addAssistantMessage: (state, action: PayloadAction<LockdropMessage>) => {
      state.messages.push(action.payload)
      state.isLoading = false
    },
    addUserMessage: (state, action: PayloadAction<string>) => {
      state.messages.push({
        content: action.payload,
        timestamp: new Date().toISOString(),
        role: 'user',
      })
      state.isLoading = true
    },
    resetTwitterChat: (state) => {
      state.messages = initialState.messages
      state.isLoading = false
      state.error = null
    },
  },
})

export const {
  addUserMessage,
  addAssistantMessage,
  resetTwitterChat,
  setIsLoading,
} = twitterChatSlice.actions

export default twitterChatSlice.reducer
