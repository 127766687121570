// LOCKDROP
export const SYN_CORTEX_MULTIPLIER = 5.5

export const LOCKDROP_ADDRESS = '0x00000010CD90B3688D249D84c616dE3A0343E60f'

export const MINIMUM_DEPOSITABLE_AMOUNT = 55n

// OTHER
export const PERMIT2_ADDRESS = '0x000000000022D473030F116dDEE9F6B43aC78BA3'

export const MAX_UINT256 =
  115792089237316195423570985008687907853269984665640564039457584007913129639935n
