import { createAsyncThunk } from '@reduxjs/toolkit'

import { POINTS_API_URL } from '@/constants/urls'

export const fetchAdditionalAnalysis = createAsyncThunk(
  'twitter/fetchAdditionalAnalysis',
  async (username: string, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${POINTS_API_URL}/generate-additional-personality-analysis`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username }),
        }
      )

      if (!response.ok) {
        throw new Error('Failed to fetch additional analysis')
      }

      return await response.json()
    } catch (error) {
      return rejectWithValue((error as Error).message)
    }
  }
)
