import { CHAINS } from '@/constants/chains'
import { type CortexChainConfig } from '@/types'

export const findChainByChainId = (chainId?: number): CortexChainConfig => {
  return CHAINS.find((c) => c.chainId === chainId) as CortexChainConfig
}

export const findChainByNetworkName = (
  networkName: string
): CortexChainConfig => {
  return CHAINS.find((c) => c.name === networkName) as CortexChainConfig
}
