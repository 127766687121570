import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface BalanceState {
  shouldRefresh: boolean
  lastUpdated: number | null
  isLoading: boolean
  error: string | null
}

const initialState: BalanceState = {
  shouldRefresh: false,
  lastUpdated: null,
  isLoading: false,
  error: null,
}

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    refreshBalances: (state) => {
      state.shouldRefresh = true
      state.isLoading = true
    },
    balancesRefreshed: (state) => {
      state.shouldRefresh = false
      state.isLoading = false
      state.lastUpdated = Date.now()
    },
    balancesError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export const { refreshBalances, balancesRefreshed, balancesError } =
  portfolioSlice.actions
export default portfolioSlice.reducer
