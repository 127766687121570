import(/* webpackMode: "eager" */ "/opt/buildhome/repo/frontend/teaser/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["HashNavigationProvider"] */ "/opt/buildhome/repo/frontend/teaser/src/providers/HashNavigationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReduxProvider"] */ "/opt/buildhome/repo/frontend/teaser/src/providers/ReduxProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WalletProvider"] */ "/opt/buildhome/repo/frontend/teaser/src/providers/WalletProvider.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/@next+third-parties@14.2.23_next@14.2.13_react@18.3.1/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/@next+third-parties@14.2.23_next@14.2.13_react@18.3.1/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/@next+third-parties@14.2.23_next@14.2.13_react@18.3.1/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@14.2.13_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/script.js");
