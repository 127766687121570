import React from 'react'
import { useDispatch } from 'react-redux'

import { setSelectedAndOverwrite } from '@/store/slices/selectedOptionsSlice'
import { windowMetadata } from '@/constants/windowMetadata'

export const ArchitectureContent = () => {
  const dispatch = useDispatch()

  const buttonClassName = [
    'w-fit',
    'p-1 px-1.5 pr-2',
    'rounded-sm',
    'text-left',
    '-ml-1',
    'dark:text-cortex-yellow',
    'hover:dark:bg-white hover:bg-cortex-yellow',
    'hover:dark:text-cortex-bg hover:text-cortex-text',
    'focus:dark:bg-white focus:bg-cortex-yellow',
    'focus:dark:text-cortex-bg focus:text-cortex-text',
    'focus:outline-none',
    'hover:before:opacity-100 focus:before:opacity-100',
  ].join(' ')

  const handleButtonClick = (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>,
    paneKey: string
  ) => {
    e.stopPropagation()
    dispatch(setSelectedAndOverwrite(paneKey))
    const activeElement = document.activeElement
    if (activeElement instanceof HTMLElement) {
      activeElement.blur()
    }
  }

  return (
    <div className="space-y-4 divide-y [&>section]:border-white/10 [&>section]:space-y-2.5 [&>section]:pt-6 [&>section:first-child]:pt-1">
      <section>
        <h2 className="text-lg text-cortex-strong/90">Cortex Agent</h2>
        <p>
          The Cortex Agent will be the easiest way to use crypto, our
          generalised trading agent. Users prompt their desired end state and
          the agent executes on it. Its core early features are:
        </p>
        <ul className="py-0.5">
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            Automated Intelligence - Access to any data source including
            on-chain, X, Arkham, protocol docs, Coingecko, DexScreener, etc.
          </li>
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            Gas & Chain Abstraction - Make transactions on any chain without
            having gas on that chain.
          </li>
          <li className="before:content-['✓'] before:text-green-500 grid grid-cols-[auto_1fr] gap-2 py-0.5">
            Biometric Accounts - Execute multiple transactions with a single
            fingerprint or Face ID, no more metamask confirmations.
          </li>
        </ul>
      </section>
    </div>
  )
}
