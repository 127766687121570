import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import { useRouter } from 'next/navigation'

import { RootState } from '@/store'
import { setPerformedFirstAction } from '@/store/slices/userSlice'
import { windowMetadata } from '@/constants/windowMetadata'
import { setSelectedAndOverwrite } from '@/store/slices/selectedOptionsSlice'
import { normalizeForAlias } from '@/utils/normalizeForAlias'

const useTypewriterEffect = (
  words: string[],
  typingSpeed: number,
  pauseDuration: number,
  cursorBlinkDuration: number,
  disabled: boolean
) => {
  const [currentWord, setCurrentWord] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [wordIndex, setWordIndex] = useState(0)
  const [showCursor, setShowCursor] = useState(true)

  useEffect(() => {
    // Disables effect, removes displayed word
    if (disabled) {
      setCurrentWord('')
      setShowCursor(false)
      return
    }

    const currentFullWord = words[wordIndex]

    const typeWriter = () => {
      if (!isDeleting && currentWord === currentFullWord) {
        // Word is complete, show blinking cursor for 2 seconds
        const cursorBlinkInterval = setInterval(() => {
          setShowCursor((prev) => !prev)
        }, cursorBlinkDuration)

        setTimeout(() => {
          clearInterval(cursorBlinkInterval)
          setIsDeleting(true)
          setShowCursor(true)
        }, pauseDuration)
      } else if (isDeleting && currentWord === '') {
        setIsDeleting(false)
        setWordIndex((prevIndex) => (prevIndex + 1) % words.length)
      } else {
        setCurrentWord((prev) =>
          isDeleting
            ? prev.slice(0, -1)
            : currentFullWord.slice(0, prev.length + 1)
        )
      }
    }

    const timer = setTimeout(
      typeWriter,
      isDeleting ? typingSpeed / 2 : typingSpeed
    )
    return () => clearTimeout(timer)
  }, [
    currentWord,
    isDeleting,
    wordIndex,
    words,
    typingSpeed,
    pauseDuration,
    cursorBlinkDuration,
    disabled,
  ])

  return { currentWord, showCursor }
}

interface InteractiveInputProps {
  inputRef: React.RefObject<HTMLInputElement>
}

export const InteractiveInput: React.FC<InteractiveInputProps> = ({
  inputRef,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [suggestion, setSuggestion] = useState('')
  const [focusedIndex, setFocusedIndex] = useState(-1)

  const { performedFirstAction } = useSelector((state: RootState) => state.user)

  const optionRefs = useRef<Array<HTMLButtonElement | null>>([])

  const dispatch = useDispatch()
  const router = useRouter()

  const visibleWindowMetadata = windowMetadata.filter(
    (w) => w.showInTerminal === true
  )

  useEffect(() => {
    setFocusedIndex(-1)
  }, [inputValue])

  useEffect(() => {
    if (focusedIndex >= 0 && focusedIndex < optionRefs.current.length) {
      optionRefs.current[focusedIndex]?.focus()
    }
  }, [focusedIndex])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const normalizedValue = normalizeForAlias(value)
    setInputValue(normalizedValue)
    if (value === '0') {
      setSuggestion('')
      return
    }
    let matchedTitle = ''
    for (const { title } of visibleWindowMetadata) {
      if (
        title.toLowerCase().startsWith(normalizedValue.toLowerCase()) &&
        normalizedValue !== ''
      ) {
        matchedTitle = title
        break
      }
    }
    if (matchedTitle && normalizedValue.length < matchedTitle.length) {
      setSuggestion(matchedTitle.slice(normalizedValue.length))
    } else {
      setSuggestion('')
    }
  }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLButtonElement>
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (focusedIndex >= 0 && focusedIndex < visibleWindowMetadata.length) {
        handleSelection(visibleWindowMetadata[focusedIndex].key)
      } else {
        const newValue = inputValue + suggestion
        const selection = visibleWindowMetadata.find(
          (item) => item.title.toLowerCase() === newValue.toLowerCase()
        )?.key
        if (selection) {
          handleSelection(selection)
        }
      }
      setInputValue('')
      setSuggestion('')
      setFocusedIndex(-1)
      inputRef.current?.focus()
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      setFocusedIndex(
        (prevIndex) => (prevIndex + 1) % visibleWindowMetadata.length
      )
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setFocusedIndex(
        (prevIndex) =>
          (prevIndex - 1 + visibleWindowMetadata.length) %
          visibleWindowMetadata.length
      )
    }
  }

  const handleOptionFocus = (index: number) => {
    setFocusedIndex(index)
  }

  const handleOptionBlur = () => {
    setTimeout(() => {
      if (!optionRefs.current.some((ref) => ref === document.activeElement)) {
        setFocusedIndex(-1)
      }
    }, 0)
  }

  const handleSelection = (selection: string) => {
    const paneData = windowMetadata.find((item) => item.key === selection)
    if (!paneData) return

    if (
      React.isValidElement(paneData.content) &&
      paneData.content.type === Link
    ) {
      router.push(paneData.content.props.href)
      return
    }

    dispatch(setSelectedAndOverwrite(selection))
  }

  const { currentWord: typewriterPlaceholder, showCursor } =
    useTypewriterEffect(
      ['Accounts', 'Cortex Agent', 'Agent Platform', 'Points'],
      200,
      2000,
      500, // Cursor blink duration in milliseconds
      performedFirstAction
    )

  return (
    <>
      <ol>
        {visibleWindowMetadata.map(({ key, title }, index) => (
          <li key={key}>
            <button
              ref={(el) => {
                optionRefs.current[index] = el
              }}
              tabIndex={0}
              type="button"
              className={`
                    p-1 px-1.5 pr-2
                    rounded-sm
                    text-left
                    -ml-1
                    dark:text-cortex-yellow
                    hover:dark:bg-white hover:bg-cortex-yellow
                    hover:dark:text-cortex-bg hover:text-cortex-text
                    focus:dark:bg-white focus:bg-cortex-yellow
                    focus:dark:text-cortex-bg focus:text-cortex-text
                    focus:outline-none
                    before:content-['•'] before:opacity-0 before:mr-1.5
                    hover:before:opacity-100 focus:before:opacity-100
                    ${index === focusedIndex ? 'bg-cortex-yellow dark:bg-white dark:text-cortex-bg text-cortex-text' : ''}
                  `}
              onClick={() => {
                if (!performedFirstAction) {
                  dispatch(setPerformedFirstAction(true))
                }
                handleSelection(key)
              }}
              onFocus={() => handleOptionFocus(index)}
              onBlur={handleOptionBlur}
              onKeyDown={handleKeyDown}
            >
              {title}/
            </button>
          </li>
        ))}
      </ol>
      <div className="flex py-2">
        &gt;&nbsp;
        <div className="relative flex-1 overflow-hidden">
          <input
            type="text"
            className="relative bg-transparent z-2 border-none focus:outline-none w-full"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            placeholder={`${typewriterPlaceholder}${showCursor ? '|' : ''}`}
          />
          {suggestion && (
            <div className="absolute inset-0 pointer-events-none z-1 user-select-none truncate">
              {inputValue}
              <span className="bg-cortex-yellow dark:bg-gray-600">
                {suggestion}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
