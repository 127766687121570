import {
  arbitrum,
  bsc,
  mainnet,
  avalanche,
  canto,
  optimism,
  polygon,
  fantom,
  cronos,
  boba,
  metis,
  aurora,
  moonbeam,
  base,
  harmonyOne,
  moonriver,
  dogechain,
  blast,
} from 'viem/chains'

interface Token {
  name: string
  symbol: string
  addresses: {
    [chainId: number]: string
  }
  decimals: {
    [chainId: number]: number
  }
  imgUrl: string
}

export const SYN: Token = {
  name: 'Synapse',
  symbol: 'SYN',
  addresses: {
    [mainnet.id]: '0x0f2d719407fdbeff09d87557abb7232601fd9f29',
    [bsc.id]: '0xa4080f1778e69467e905b8d6f72f6e441f9e9484',
    [polygon.id]: '0xf8f9efc0db77d8881500bb06ff5d6abc3070e695',
    [fantom.id]: '0xE55e19Fb4F2D85af758950957714292DAC1e25B2',
    [arbitrum.id]: '0x080f6aed32fc474dd5717105dba5ea57268f46eb',
    [avalanche.id]: '0x1f1E7c893855525b303f99bDF5c3c05Be09ca251',
    [harmonyOne.id]: '0xE55e19Fb4F2D85af758950957714292DAC1e25B2',
    [boba.id]: '0xb554A55358fF0382Fb21F0a478C3546d1106Be8c',
    [metis.id]: '0x67C10C397dD0Ba417329543c1a40eb48AAa7cd00',
    [moonriver.id]: '0xd80d8688b02B3FD3afb81cDb124F188BB5aD0445',
    [moonbeam.id]: '0xF44938b0125A6662f9536281aD2CD6c499F22004',
    [optimism.id]: '0x5A5fFf6F753d7C11A56A52FE47a177a87e431655',
    [cronos.id]: '0xFD0F80899983b8D46152aa1717D76cba71a31616',
    [aurora.id]: '0xd80d8688b02B3FD3afb81cDb124F188BB5aD0445',
    [dogechain.id]: '0xDfA53EeBA61D69E1D2b56b36d78449368F0265c1',
    [canto.id]: '0x555982d2E211745b96736665e19D9308B615F78e',
    [base.id]: '0x432036208d2717394d2614d6697c46DF3Ed69540',
    [blast.id]: '0x9592f08387134e218327E6E8423400eb845EdE0E',
  },
  decimals: {
    [mainnet.id]: 18,
    [bsc.id]: 18,
    [polygon.id]: 18,
    [fantom.id]: 18,
    [arbitrum.id]: 18,
    [avalanche.id]: 18,
    [harmonyOne.id]: 18,
    [boba.id]: 18,
    [metis.id]: 18,
    [moonriver.id]: 18,
    [moonbeam.id]: 18,
    [optimism.id]: 18,
    [cronos.id]: 18,
    [aurora.id]: 18,
    [dogechain.id]: 18,
    [canto.id]: 18,
    [base.id]: 18,
    [blast.id]: 18,
  },
  imgUrl:
    'https://45a97b3d.sanguine-fe.pages.dev/_next/static/media/syn.042f8cd0.svg',
}

// TODO: Actual CX token address is
// 0x000000000000012DeF132E61759048bE5b5C6033
// and 18 decimals

// Below is USDC for testing

export const CX: Token = {
  name: 'Cortex',
  symbol: 'CX',
  addresses: {
    [mainnet.id]: '0x000000000000012DeF132E61759048bE5b5C6033',
    [bsc.id]: '0x000000000000012DeF132E61759048bE5b5C6033',
    [arbitrum.id]: '0x000000000000012DeF132E61759048bE5b5C6033',
    [avalanche.id]: '0x000000000000012DeF132E61759048bE5b5C6033',
    [optimism.id]: '0x000000000000012DeF132E61759048bE5b5C6033',
    [base.id]: '0x000000000000012DeF132E61759048bE5b5C6033',
    [polygon.id]: '0x000000000000012DeF132E61759048bE5b5C6033',
  },
  decimals: {
    [mainnet.id]: 18,
    [bsc.id]: 18,
    [arbitrum.id]: 18,
    [avalanche.id]: 18,
    [optimism.id]: 18,
    [base.id]: 18,
    [polygon.id]: 18,
  },
  imgUrl:
    'https://teaser-features.cortex-teaser.pages.dev/_next/static/media/cortex-logo.dd195be5.svg',
}
