import { useDispatch } from 'react-redux'

import type { AppDispatch } from '@/store'
import {
  setSuggestedResponses,
  clearSuggestedResponses,
} from '@/store/slices/lockdropChatSlice'
import { type LockdropMessage } from '@/types/lockdrop'

// export const SUGGESTIONS_API_ENDPOINT =
//   'https://twitter-emulator-copy-production.up.railway.app/suggest-responses'
export const SUGGESTIONS_API_ENDPOINT =
  'https://stake.cortexprotocol.com/suggest-responses'
// export const SUGGESTIONS_API_ENDPOINT = 'http://localhost:3000/suggest-responses'

export const useSuggestedResponses = () => {
  const dispatch = useDispatch<AppDispatch>()

  const fetchSuggestions = async (messages: LockdropMessage[]) => {
    try {
      const response = await fetch(SUGGESTIONS_API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chatHistory: messages.map((msg) => ({
            role: msg.role,
            content: msg.content || '',
          })),
        }),
      })

      if (!response.ok) {
        throw new Error('Failed to fetch suggestions')
      }

      const data = await response.json()
      dispatch(setSuggestedResponses(data.suggested_responses))
    } catch (error) {
      console.error('Error fetching suggestions:', error)
      dispatch(clearSuggestedResponses())
    }
  }

  return { fetchSuggestions }
}
