import { createAsyncThunk } from '@reduxjs/toolkit'
import { type Address, erc20Abi, formatUnits } from 'viem'
import { multicall } from '@wagmi/core'

import { wagmiConfig } from '@/wagmi/wagmiConfig'
import { SYN, CX } from '@/constants/tokens'
import { LOCKDROP_ADDRESS } from '@/constants'
import lockdropAbi from '@/constants/abis/LockdropAbi.json'

const tokens = [SYN, CX]

type LockedAmount = bigint

export const fetchSynBalances = createAsyncThunk(
  'lockdropPortfolio/fetchSynBalances',
  async (address: Address | undefined, { rejectWithValue }) => {
    if (!address) {
      return rejectWithValue('No address provided')
    }

    try {
      const balanceRequests = tokens.flatMap((token) =>
        Object.entries(token.addresses).map(([chainId, tokenAddress]) => ({
          token,
          chainId: Number(chainId),
          tokenAddress: tokenAddress as Address,
        }))
      )

      const multicallRequests = balanceRequests.map(
        ({ tokenAddress, chainId }) => ({
          address: tokenAddress,
          abi: erc20Abi,
          functionName: 'balanceOf' as const,
          args: [address] as [Address],
          chainId,
        })
      )

      // Add lockedAmountOf request for each supported chain
      const lockdropRequests = balanceRequests
        .filter(({ token }) => token.symbol === 'SYN')
        .map(({ chainId }) => ({
          address: LOCKDROP_ADDRESS,
          abi: lockdropAbi,
          functionName: 'lockedAmountOf' as const,
          args: [address] as [Address],
          chainId,
        }))

      const results = await Promise.all(
        balanceRequests.map(async ({ token, chainId, tokenAddress }, index) => {
          try {
            const contracts = [multicallRequests[index]]

            // Add lockdrop contract call if this is SYN
            if (token.symbol === 'SYN') {
              const lockdropRequest = lockdropRequests.find(
                (req) => req.chainId === chainId
              )
              if (lockdropRequest) {
                contracts.push(lockdropRequest as any)
              }
            }

            const chainResults = await multicall(wagmiConfig, {
              contracts,
              chainId,
            })

            const balance =
              chainResults[0].status === 'success'
                ? BigInt(chainResults[0].result)
                : 0n

            const lockedAmount =
              token.symbol === 'SYN' && chainResults[1]?.status === 'success'
                ? (BigInt(chainResults[1].result) as LockedAmount)
                : 0n

            const decimals = token.decimals[chainId]
            const parsedBalance = formatUnits(balance, decimals)
            const parsedLockedAmount =
              token.symbol === 'SYN' ? formatUnits(lockedAmount, decimals) : '0'

            return {
              chainId,
              address: tokenAddress,
              balance: balance.toString(),
              parsedBalance: parsedBalance ?? '0.0',
              symbol: token.symbol,
              lockedAmount: lockedAmount.toString(),
              parsedLockedAmount,
            }
          } catch (err) {
            return {
              chainId,
              address: tokenAddress,
              balance: '0',
              parsedBalance: '0.0',
              symbol: token.symbol,
              lockedAmount: '0',
              parsedLockedAmount: '0.0',
            }
          }
        })
      )

      return results
    } catch (err) {
      return rejectWithValue(
        err instanceof Error ? err.message : 'Unknown error'
      )
    }
  }
)
