export const formatBalance = (amount?: number) => {
  if (!amount) return 0

  if (amount >= 1_000_000) {
    return `${(amount / 1000000).toFixed(2)}M`
  }

  if (amount > 1_000) {
    return `${(amount / 1000).toFixed(2)}K`
  }

  if (amount < 0.0001) {
    return amount.toExponential(2)
  }

  return amount.toLocaleString('en-US', {
    maximumFractionDigits: 3,
  })
}
