import {
  arbitrum,
  aurora,
  avalanche,
  base,
  bsc,
  blast,
  boba,
  canto,
  cronos,
  dfk,
  fantom,
  harmonyOne,
  mainnet,
  metis,
  moonbeam,
  moonriver,
  optimism,
  polygon,
} from '@wagmi/core/chains'

import { findChainByChainId } from '@/utils/findChainByChainId'

const ALL_CHAINS_WITH_SYN = [
  arbitrum,
  aurora,
  avalanche,
  base,
  blast,
  // Boba hidden bc RPC failures
  // boba,
  bsc,
  // Canto hidden bc slows down app load
  // canto,
  cronos,
  dfk,
  mainnet,
  fantom,
  harmonyOne,
  metis,
  moonbeam,
  moonriver,
  optimism,
  polygon,
]

export const LOCKDROP_SUPPORTED_CHAINS = [
  mainnet,
  arbitrum,
  avalanche,
  base,
  optimism,
  bsc,
  polygon,
]

export const supportedChains = ALL_CHAINS_WITH_SYN.map((chain) => {
  const c = findChainByChainId(chain.id)
  return {
    ...chain,
    iconUrl: c.imgUrl,
  }
})
