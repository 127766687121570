import { useState, useEffect } from 'react'
import { multicall } from '@wagmi/core'
import { erc20Abi } from 'viem'

import { LOCKDROP_SUPPORTED_CHAINS } from '@/wagmi/supportedChains'
import lockdropAbi from '@/constants/abis/LockdropAbi.json'
import { wagmiConfig } from '@/wagmi/wagmiConfig'
import { LOCKDROP_ADDRESS } from '@/constants'
import { SYN } from '@/constants/tokens'

interface LockdropData {
  chainId: number
  totalLockedAmount: string
  totalSupply: string
}

export const useLockdropStats = () => {
  const [data, setData] = useState<LockdropData[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setError(null)
      const results: LockdropData[] = []

      try {
        for (const chain of LOCKDROP_SUPPORTED_CHAINS) {
          try {
            const lockDropContract = {
              address: LOCKDROP_ADDRESS,
              abi: lockdropAbi,
              chainId: chain.id,
            } as const

            const synContract = {
              address: SYN.addresses[chain.id],
              abi: erc20Abi,
              chainId: chain.id,
            } as const

            const contracts = [
              {
                ...lockDropContract,
                functionName: 'totalLockedAmount',
              },
              {
                ...synContract,
                functionName: 'totalSupply',
              },
            ]

            const response = await multicall(wagmiConfig, {
              contracts: contracts as any,
              chainId: chain.id,
            })

            if (!response || !Array.isArray(response)) {
              console.error('Invalid multicall response for chain:', chain.id)
              continue
            }

            const [{ result: totalLockedAmount }, { result: totalSupply }] =
              response

            results.push({
              chainId: chain.id,
              totalLockedAmount: totalLockedAmount?.toString() || '0',
              totalSupply: totalSupply?.toString() || '0',
            })
          } catch (innerError) {
            console.error(
              `Error fetching data for chain ${chain.id}:`,
              innerError
            )
          }
        }

        setData(results)
      } catch (err) {
        console.error('Error in fetchData:', err)
        setError(err as any)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const totalLockedAmountAggregated = data
    .reduce((acc: bigint, curr) => {
      const currAmount = BigInt(curr.totalLockedAmount)
      return acc + currAmount
    }, BigInt(0))
    .toString()

  return {
    data,
    totalLockedAmount: totalLockedAmountAggregated,
    loading,
    error,
  }
}
