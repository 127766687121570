import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '@/store'

interface PaneProps {
  header: string
  content: React.ReactNode
  onSelect: () => void
  onSelectNext: () => void
  onSelectPrevious: () => void
  onRemove: () => void
  isSelected: boolean
  isFirstOption: boolean
  isLastOption: boolean
  initialPosition: { x: number; y: number }
  id: string
}

export const Pane: React.FC<PaneProps> = ({
  header,
  content,
  onSelect,
  onSelectNext,
  onSelectPrevious,
  onRemove,
  isSelected,
  isFirstOption,
  isLastOption,
  initialPosition,
  id,
}) => {
  const [position, setPosition] = useState(initialPosition)
  const [dragging, setDragging] = useState(false)
  const [offset, setOffset] = useState({ x: 0, y: 0 })
  const [dragged, setDragged] = useState(false)
  const detailsRef = useRef<HTMLDetailsElement>(null)

  const dispatch = useDispatch()
  const { zIndex } = useSelector(
    (state: RootState) =>
      state.selectedOptions.selectedOptions.find((opt) => opt.id === id) || {
        zIndex: 100,
      }
  )

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    onSelect()

    setDragging(true)
    setDragged(false)
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    })
  }

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (dragging) {
        const newX = e.clientX - offset.x
        const newY = e.clientY - offset.y
        setPosition({ x: newX, y: newY })
        setDragged(true)
      }
    },
    [dragging, offset]
  )

  const handleMouseUp = () => {
    setDragging(false)
  }

  useEffect(() => {
    if (dragging) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    } else {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [dragging, handleMouseMove])

  const paneClassName = [
    'pane',
    'left-0 md:relative flex flex-col justify-self-end',
    'sm:max-w-xl sm:h-fit',
    // 'animate-fadeIn',
    'border border-cortex-border rounded-md',
    isSelected
      ? 'dark:border-white/10 shadow-lg' // use border-white/15 if multiple windows are brought back
      : 'dark:border-white/10 shadow-sm',
    // 'bg-cortex-surface/85 backdrop-blur-lg',
    'bg-cortex-surface',
    'font-mono text-base',
  ].join(' ')

  const summaryClassName =
    [
      'flex items-center',
      'group-open:border-b border-cortex-border dark:border-white/10',
      'rounded-md group-open:rounded-b-none',
      'px-4 py-1',
      'select-none',
      'cursor-pointer',
    ].join(' ') + (isSelected ? ' bg-cortex-platform text-cortex-strong' : '')

  const summaryButtonClassName = [
    'opacity-65 hover:opacity-100',
    'dark:hover:text-cortex-yellow',
    'ml-2 p-1',
  ].join(' ')

  const toggleOpenPane = () => {
    if (detailsRef.current) {
      detailsRef.current.open = !detailsRef.current.open
    }
  }

  return (
    <article
      className={paneClassName}
      onMouseDown={onSelect}
      style={{
        left: window.innerWidth >= 768 ? `${position.x}px` : undefined,
        top: `${position.y}px`,
        zIndex,
      }}
    >
      <details ref={detailsRef} open className="group grow">
        <summary
          className={summaryClassName}
          onClick={(e) => e.preventDefault()}
          onDoubleClick={toggleOpenPane}
          onMouseDown={handleMouseDown}
        >
          <header className="grow before:content-['#_'] before:opacity-65">
            {header}
          </header>
          {/* <button
            type="button"
            className={summaryButtonClassName}
            onClick={(e) => {
              e.stopPropagation()
              toggleOpenPane()
            }}
          >
            &minus;
          </button> */}
          <button
            type="button"
            className={summaryButtonClassName}
            onClick={(e) => {
              e.stopPropagation()
              console.log('onRemove')
              onRemove()
            }}
          >
            &times;
          </button>
        </summary>
        <div className="p-4 font-sans tracking-wider">{content}</div>
      </details>
      <div className="flex md:hidden justify-between items-center border-t rounded-b-lg border-cortex-border p-2 bottom-0 bg-cortex-platform">
        <button
          onClick={onSelectPrevious}
          className="group/step hover:text-cortex-yellow disabled:text-white/30 disabled:pointer-events-none before:content-['<_']"
          disabled={isFirstOption}
        >
          <span className="group-hover/step:underline">Prev</span>
        </button>
        <button
          onClick={onSelectNext}
          className="group/step hover:text-cortex-yellow disabled:text-white/30 disabled:pointer-events-none after:content-['_>']"
          disabled={isLastOption}
        >
          <span className="group-hover/step:underline">Next</span>
        </button>
      </div>
    </article>
  )
}
