import { type Chain } from 'viem'
import { createConfig } from '@wagmi/core'
import {
  metaMaskWallet,
  rabbyWallet,
  coinbaseWallet,
  rainbowWallet,
  walletConnectWallet,
  trustWallet,
  ledgerWallet,
  frameWallet,
  safeWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { connectorsForWallets } from '@rainbow-me/rainbowkit'

import { supportedChains } from './supportedChains'
import { createTransports } from './createTransports'

const appName = 'Cortex'
const projectId = 'e6c80af99edc2c593882f37e99637a4a'

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Wallets',
      wallets: [
        metaMaskWallet,
        walletConnectWallet,
        coinbaseWallet,
        rainbowWallet,
        rabbyWallet,
        trustWallet,
        ledgerWallet,
        frameWallet,
        safeWallet,
      ],
    },
  ],
  {
    appName,
    projectId,
  }
)

const transports = createTransports(supportedChains as Chain[])

export const wagmiConfig = createConfig({
  connectors,
  chains: supportedChains as unknown as readonly [Chain, ...Chain[]],
  transports,
  ssr: true,
})
